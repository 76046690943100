// src/pages/Products.js
import React from 'react';
import { Container, Typography, Box, Link, List, ListItem } from '@mui/material';

const Products = () => {
  return (
    <Container maxWidth="md">
      <Box
        mt={4}
        className="bubble"
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <Typography variant="h4" gutterBottom>Products</Typography>

        {/* im talking too much 
        <Typography variant="h6" mt={2} mb={4}>
          I'm not a Person/Company owner that'd ever ask you to donate during a
          purchase. Companies do that because they want to use your money for a
          tax break! Deep down they really don't care about donating. That being
          said I'll be taking the profits from purchases and donating 10% myself
          so don't worry! You buying my products is automatically donating.
        </Typography>
        */}
        <Typography variant="h6" mt={2} mb={4}>For Full transparency I make $5.55 for each T-Shirt Purchased. I am trying to keep base price under $24.99 anything more for a T-shirt seems like too much sauce.</Typography>
        <Typography variant="h6" mt={2} mb={4}>I make exactly $3 for ball snapback sales</Typography>
        <Box mt={2} mb={2}>
          <List>
            <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <Link
                href="https://alexanderfields.printful.me/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#fde047', fontSize: "1.2rem" }}
              >
                Printful Store
              </Link>
            </ListItem>

            <Typography variant="h6" mt={2} mb={4}>Amazon takes $1.55 per T-Shirt so i make exactly $4.00 on Amazon</Typography>

            <ListItem style={{ display: "flex", justifyContent: "center" }}>
              {/*
              <Typography style={{ color: '#888', fontSize: "1.2rem" }}>
                Amazon
              </Typography>
              */}
              <Link
                href="https://www.amazon.com/hz/wishlist/dl/invite/2QcWzob?ref_=wl_share"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#fde047', fontSize: "1.2rem" }}
              >
                Amazon
              </Link>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Container>
  );
};

export default Products;
